const framework_base = import.meta.env.VITE_IONIC_SITE_URL!;
const ionic_base = import.meta.env.VITE_IONIC_IO_URL!;
const blog_url = 'https://blog.ionicframework.com/';
const status_url = 'https://status.ionicframework.com/';
const pro_docs_base = 'https://ionicframework.com/docs/appflow/';
const pro_docs_base_beta = 'https://ionicframework.com/docs/appflow/';
const zendesk_base = 'https://ionic.zendesk.com/';
const products_page = framework_base + 'products/';
const forum_base = 'https://forum.ionicframework.com/';
const pro_quickstart = pro_docs_base + 'quickstart';
const native_quickstart = framework_base + 'docs/enterprise/quickstart';

interface UrlDictionary {
  readonly [index: string]: string;
}

export const Urls: UrlDictionary = {
  AppflowQuickstart: pro_quickstart,
  AuthorizationEndpoint: ionic_base + 'oauth/authorize',
  Signup: ionic_base + 'signup',
  Logout: ionic_base + 'logout',
  ResetPassword: ionic_base + 'reset-password',
  FrameworkHome: framework_base,
  FrameworkDocs: framework_base + 'docs',
  NativeDocs: framework_base + 'docs/native',
  Privacy: framework_base + 'privacy',
  TermsOfService: framework_base + 'tos',
  Support: framework_base + 'support',
  EnterpriseSdk: ionic_base + 'enterprise-sdk',
  EnterpriseSdkPricing: ionic_base + 'enterprise-sdk/pricing',
  Pricing: framework_base + 'pricing',
  CliInstall: framework_base + 'docs/intro/cli',
  StatusPage: status_url,
  Blog: blog_url,
  DocsHome: pro_docs_base,
  ConnectDocs: pro_docs_base + 'quickstart/connect',
  PushDocs: pro_docs_base + 'quickstart/push',
  ViewDocs: pro_docs_base + 'view',
  DeployDocs: pro_docs_base + 'deploy/intro',
  DeploySetupDocs: pro_docs_base + 'deploy/setup',
  DeployDisableForDevDocs: pro_docs_base + 'quickstart/deploy#disabling-deploy-for-development',
  ChannelsDocs: pro_docs_base + 'deploy/channels',
  PluginVariableDocs: pro_docs_base + 'quickstart/installation#plugin-configuration',
  DestinationDocs: pro_docs_base + 'destinations/intro',
  DestinationDestinationsDocs: pro_docs_base + 'destinations/destinations',
  AppleAppStoreDestinationDocs: pro_docs_base + 'destinations/apple',
  GooglePlayDestinationDocs: pro_docs_base + 'destinations/google',
  PackageDocs: pro_docs_base + 'package/intro',
  AutomationDocs: pro_docs_base_beta + 'automation/intro',
  EnvironmentDocs: pro_docs_base_beta + 'automation/environments',
  NativeConfigDocs: pro_docs_base_beta + 'package/native-configs',
  WebPreviewDocs: pro_docs_base + 'web-previews',
  NativeQuickstart: native_quickstart,
  GettingStarted: pro_docs_base + 'basics/getting-started',
  GitBasics: pro_docs_base + 'basics/git',
  SSHKeysDocs: pro_docs_base + 'basics/git/#adding-ssh-keys',
  SecurityProfilesDocs: pro_docs_base + 'package/credentials',
  PackageIOSDocs: pro_docs_base + 'package/credentials#ios-credentials',
  PackageAndroidDocs: pro_docs_base + 'package/credentials#android-credentials',
  ConcurrencyLimitsDocs: pro_docs_base + 'concurrency-limits',
  BuildStackDocs: pro_docs_base + 'build-stacks',
  DevAppDocs: pro_docs_base + 'devapp',
  WebhooksDocs: pro_docs_base + 'package/webhooks',
  ProForum: forum_base + 'c/ionic-appflow/20',
  CreatorApp: 'http://creator.ionic.io/',
  CreatorDocs: 'http://docs.usecreator.com/',
  Zendesk: zendesk_base,
  SupportRequest: zendesk_base + 'hc/en-us/requests/new',
  ProductsHome: products_page,
  ProductsView: products_page + 'view',
  ViewPlayStore: 'https://play.google.com/store/apps/details?id=com.ionicframework.view',
  ViewAppStore: 'https://itunes.apple.com/us/app/ionic-view/id1271789931',
  StudioMacUrl: 'https://s3.amazonaws.com/ionic-studio-releases/latest/ionic-studio.dmg',
  StudioPcUrl: 'https://s3.amazonaws.com/ionic-studio-releases/latest/ionic-studio.exe',
  IonicDocs: 'https://ionic.io/docs',
  AuthConnectGettingStarted: 'https://ionic.io/docs/auth-connect',
  IdentityVaultGettingStarted: 'https://ionic.io/docs/identity-vault',
  IonicPaymentsApplePayGettingStarted: 'https://ionic.io/docs/apple-pay',
  IonicPaymentsGooglePayGettingStarted: 'https://ionic.io/docs/google-pay',
  IntuneGettingStarted: 'https://ionic.io/docs/intune',
  ZebraDataWedgeGettingStarted: 'https://ionic.io/docs/zebra-datawedge',
  CloudCLIDocs: 'https://ionic.io/docs/appflow/cli/overview',
  CloudCLIMarketingPage: 'https://ionic.io/appflow/continuous-delivery',
  CloudCLINativeBuildsDocs: 'https://ionic.io/docs/appflow/cli/native-builds',
  CloudCLILiveUpdatesDocs: 'https://ionic.io/docs/appflow/cli/live-updates',
  CloudCLIAppStoreDeployDocs: 'https://ionic.io/docs/appflow/cli/app-store-deploy',
  AddingNewCredentials: 'https://ionic.io/docs/appflow/package/adding-credentials',
  SecureStorageGettingStarted: 'https://ionic.io/docs/secure-storage',
  CapacitorGettingStarted: 'https://capacitorjs.com/docs/getting-started',
  PortalsGettingStarted: 'https://ionic.io/docs/portals/getting-started/guide#install',
  PortalsiOSInstallGuide: 'https://ionic.io/docs/portals/getting-started/guide#install',
  PortalsAndroidInstallGuide: 'https://ionic.io/docs/portals/getting-started/guide#install',
  PortalsReactNativeInstallGuide: 'https://ionic.io/docs/portals/getting-started/guide#install',
  PortalsCapacitorInstallGuide: 'https://ionic.io/docs/portals/for-capacitor/overview#getting-started',
  PortalsProductTeamCalendly: 'https://calendly.com/jthoms1/mobile-micro-frontends',
  VSCode: 'https://ionic.link/vscode',
};
